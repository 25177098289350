import { ApiSettings } from '@hultafors/shared/types';

import { NavItemFragment } from '@hultafors/hellberg/types';

import { routes } from './constants';
import { wordifySlug } from './stringHandling';

export const constructBackLink = (
  route: string,
  parentSlug: string,
  parentParentSlug?: string | null
) => {
  const result = {
    title: '',
    url: '',
  };

  if (parentSlug && parentParentSlug) {
    result.url = `/${route}/${parentParentSlug}/${parentSlug}`;
    result.title = wordifySlug(parentSlug);
  } else if (parentSlug && !parentParentSlug) {
    result.url = `/${route}/${parentSlug}`;
    result.title = wordifySlug(parentSlug);
  }

  return result;
};

// We had a problem with mixed links sometimes starting with / and sometimes not
export const fixSlash = (url: string) => {
  if (url.startsWith('/')) {
    return url;
  }
  return `/${url}`;
};

interface FindParentCategory {
  allNavItems: NavItemFragment[];
  categorySlug?: string;
  menuTitle?: string;
}

export const findParentCategory = ({
  allNavItems,
  categorySlug,
  menuTitle = 'Products',
}: FindParentCategory) => {
  // TODO fix this
  // const node = allNavItems.find((item: NavItemRecord) => {
  //   if (item.submenu) {
  //     return item.submenu.title === menuTitle;
  //   }
  // });

  // if (!node?.submenu?.menuItems) {
  //   return null;
  // }

  // let foundNode = false;
  // let parentNode = null;

  // for (let i = node.submenu.menuItems.length; i >= 0; i--) {
  //   if (!foundNode) {
  //     if (node.submenu.menuItems[i]?.pageLink.slug === categorySlug) {
  //       if (node.submenu.menuItems[i].isParent) {
  //         // node is already parent, return null
  //         return null;
  //       }
  //       foundNode = true;
  //     }
  //   } else {
  //     if (node.submenu.menuItems[i].isParent) {
  //       parentNode = node.submenu.menuItems[i];
  //       break;
  //     }
  //   }
  // }
  return null;
};

const createBaseUrl = (settings: ApiSettings) => {
  return settings?.language?.urlPrefix
    ? `/${settings?.language.urlPrefix}/`
    : '/';
};

export const createUrl = (url: string, settings: ApiSettings): string => {
  if (!url) {
    return '/';
  }
  if (url.startsWith('http')) {
    // directly render outgoing links
    return url;
  }
  // Make sure we take care of internal url:s with or without /
  if (url.startsWith('/')) {
    url = url.substr(1, url.length - 1);
  }

  const baseUrl = createBaseUrl(settings);
  // TODO fix slash if starting with slash or not
  return `${baseUrl}${url}`;
};
export function getTitleForSlug(
  slug: string,
  allNavItems: NavItemFragment[]
): string {
  if (!slug) {
    return '';
  }

  let title = '';

  allNavItems?.forEach((item) => {
    if (item.url?.substring(1) === slug) {
      title = item.title || '';
    } else {
      item.children?.forEach((child) => {
        if (child?.pageLink?.slug === slug) {
          title = child.title || '';
        } else {
          child?.children?.forEach((grandChild) => {
            if (grandChild?.pageLink?.slug === slug) {
              title = grandChild.title || '';
            }
          });
        }
      });
    }
  });
  return title;
}

export const getParentSlug = (
  slug: string,
  allNavItems: NavItemFragment[],
  recordType: string,
  isContentArea?: boolean
) => {
  if (recordType === 'InformationPageRecord') {
    const guideMenu = allNavItems.find(
      (item) => item.url === `/${routes.GUIDES}`
    );

    let parent = guideMenu?.children?.find(
      (item) => item?.pageLink?.slug === slug
    );

    // Need to do this check for ExploreAreaBlock to set slug properly
    if (isContentArea) {
      parent = guideMenu?.children?.find((item) =>
        item?.children?.map((child) => child?.pageLink?.slug === slug)
      );
    }

    return parent;
  } else if (recordType === 'CategoryPageRecord') {
    const productMenu = allNavItems.find(
      (item) => item.url === `/${routes.PRODUCTS}`
    );
    return productMenu?.children?.find((item) => item?.pageLink?.slug === slug);
  }

  return null;
};

export const getPlugLinkUrl = (
  recordType: string,
  slug: string,
  allNavItems?: NavItemFragment[],
  isContentArea?: boolean
) => {
  let parent;

  if (allNavItems) {
    parent = getParentSlug(slug, allNavItems, recordType, isContentArea);
  }
  switch (recordType) {
    case 'CampaignPageRecord':
      return `/${routes.CAMPAIGN}/${slug}`;
    case 'InformationPageRecord':
      return `/${routes.GUIDES}/${parent?.pageLink?.slug}/${slug}`;
    case 'InformationListPageRecord':
      return `/${slug}`;
    case 'CategoryPageRecord':
      return parent
        ? `products/${parent.pageLink?.slug}/${slug}`
        : `products/${slug}`;
  }
};
